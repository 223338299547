import React from "react"

const Gachi = () => {
    return (
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1lHXfGAlp58?listType=playlist&amp;list=PL-VMa2rh7q_ZQvmRt0dqidd9GUC-_42pG&amp;index=5&amp;autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>

        </iframe>
    )
}

export default Gachi

